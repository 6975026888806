<template>
  <div>
    <Navbar />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column blog-container-column">
            <h1 class="title is-2 title-font">Product Roadmap</h1>
            <div class="content blog is-medium">
              <h2 class="title-font">Q3 2021</h2>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                DEX
                <router-link to="/dex" class="transition-faster is-size-6">
                  Released 🚀
                </router-link>
              </p>
              <p>
                Trade hundreds of markets directly on Ethereum. No account
                required. Invest using ERC20 tokens. Earn tokens by staking MPH.
              </p>

              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Wallet
                <router-link to="/wallet" class="transition-faster is-size-6">
                  Released 🚀
                </router-link>
              </p>
              <p>
                Open-source crypto wallet that's easy to use, secure, fully
                recoverable, and non-custodial.
              </p>

              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Crypto Deposits
                <span class="is-size-6">
                  Released 🥳
                </span>
              </p>
              <p>
                Support for account funding using BTC, ETH, LTC and BCH.
              </p>

              <h2 class="title-font">Coming Soon</h2>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Sidechain Staking
              </p>
              <p>
                Savings account built right into the Morpher app. Earn interest
                on any MPH that's not invested in markets. Up to 5.48% a year.
              </p>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Trading Competition
              </p>
              <p>Compete against traders around the world to win big.</p>

              <h2 class="title-font">Next Features</h2>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Bank Deposits/Withdrawals & Card Deposits/Withdrawals
              </p>
              <p>
                Building partnerships with banks and payment processors for
                seamless account funding.
              </p>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Limit Orders
              </p>
              <p>
                Open new buy or sell positions with custom limit orders. Set the
                price to be hit and time when that order is valid.
              </p>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Suggested Markets
              </p>
              <p>
                Discover trending markets with traders like you. Find the
                markets you should be following.
              </p>

              <h2 class="title-font">Projects</h2>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Mobile App
                <span class="is-size-6 has-text-weight-medium">(Beta Q4 2021)</span>
              </p>
              <p>
                Native mobile trading app for iOS and Android.
              </p>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                MPH Liquidity
                <span class="is-size-6 has-text-weight-medium">(Q4 2021)</span>
              </p>
              <p>
                Creating a more active and healthier market for MPH tokens on
                Uniswap & Gate.
              </p>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Arena
              </p>
              <p>
                Trade stocks & crypto using stablecoins on Binance Smart Chain.
              </p>
              <p class="is-size-5 no-spacing has-text-weight-semibold">
                Decentralized Oracle
              </p>
              <p>
                Like Chainlink but actually decentralized and designed for
                active on-chain trading. MPH will be used for governance and
                rewarding data contributors.
              </p>
              <p class="is-size-6 mt-20">
                Not seeing the feature you want?
                <a
                  href="https://discord.gg/CBxuAhPSCj"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  class="transition-faster"
                >Come tell us on Discord
                  <b-icon icon="fab fa-discord" pack="fab" size="is-small" /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer :disclaimerContent="1" />
  </div>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "Roadmap",
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			headParams: {
				title: this.$t('meta.ROADMAP_TITLE'),
				description:
					this.$t('meta.ROADMAP_DESC'),
			},
		};
	},
	mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_homepage_fb.png",
          id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
          id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_homepage_tw.png",
          id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
};
</script>

<style scoped>
.blog-container-column {
	max-width: 800px;
}
.blog {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.blog .title-font {
	font-size: 1.5rem;
	margin-top: 3rem;
	margin-bottom: 0.5rem;
}
p.no-spacing {
	margin-bottom: 0;
}
p {
	font-size: 16px;
}
.mt-20 {
	margin-top: 24px;
}
</style>
